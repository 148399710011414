import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  headers: any;
  _baseURL: any;
  user: any;
  deviceId: number;
  deviceInfo: any;
  constructor(
    private httpClient: HttpClient,
    private globaols: Globals,
    private deviceService:DeviceDetectorService
    ) {
    this.deviceId =Date.now();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let devicedetail:any= {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    }
    this.user=this.globaols.getUser();
    this.headers = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', JSON.stringify(devicedetail)).set('Access-Token',this.user.token);
    this._baseURL = environment.appConfig.apiTemplateUrl;
  }

  _getTemplateList(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/communicator/v1/newsletter/get/templates',obj, {headers: this.headers });
  }
  _getTemplateDetails(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/communicator/v1/newsletter/get/template-details',obj, {headers: this.headers });
  }
  _addTemplate(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/communicator/v1/newsletter/add/template',obj, {headers: this.headers });
  }
  _sendNewsletter(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/communicator/v1/newsletter/send/newsletter',obj, {headers: this.headers });
  }
  _getNotificationList(obj: any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'api/communicator/v1/communication/get/notification', obj, { headers: this.headers });
  }
}