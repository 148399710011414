import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  headers: any;
  _baseURL: any;
  user: any;
  deviceId: number;
  deviceInfo: any;
  constructor(
    private httpClient: HttpClient,
    private globaols: Globals,
    private deviceService:DeviceDetectorService
    ) {
    this.deviceId =Date.now();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let devicedetail:any= {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    }
    this.user=this.globaols.getUser();
    this.headers = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', JSON.stringify(devicedetail)).set('Access-Token',this.user.token);
    this._baseURL = environment.appConfig.apiUrl;
  }
  _getProfile(): Observable<any>{
    let obj = {
      token:this.user.token
    }
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/details/token',obj, {headers: this.headers });
  }
  _updateProfile(obj:any): Observable<any>{
    return this.httpClient.put(this._baseURL + 'api/ims/v1/partner/update', obj, {headers: this.headers });
  }
  _updatePassword(obj:any): Observable<any>{
    obj.token=this.user.token;
    return this.httpClient.put(this._baseURL + 'api/ims/v1/partner/change/password', obj, {headers: this.headers });
  }
  _updateEmail(obj:any): Observable<any>{
    return this.httpClient.put(this._baseURL + 'api/ims/v1/partner/email/update', obj, {headers: this.headers });
  }
  _updateMobile(obj:any): Observable<any>{
    return this.httpClient.put(this._baseURL + 'api/ims/v1/partner/mobile/update', obj, {headers: this.headers });
  }
  _verifyEmailUpdate(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/verify/email/update', obj, {headers: this.headers });
  }
  _verifyMobileUpdate(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/verify/mobile/update', obj, {headers: this.headers });
  }
  _getAgendaList(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/agenda/get/agenda', obj,{headers: this.headers });
  }
  _addAgenda(obj: any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/agenda/add/agenda', obj,{headers: this.headers });
  }
  _updateAgenda(obj: any): Observable<any>{
    return this.httpClient.put(this._baseURL + 'api/ims/v1/agenda/update/agenda', obj,{headers: this.headers });
  }
  _deleteAgenda(obj:any): Observable<any> {
  
    return this.httpClient.post(this._baseURL + 'api/ims/v1/agenda/delete/agenda', obj, { headers: this.headers });
  }
  _synAgendaGoogleCalendar(obj: any, gtoken:any): Observable<any>{
    return this.httpClient.post('https://www.googleapis.com/calendar/v3/calendars/primary/events', obj, { headers: { Authorization: `Bearer ${gtoken}` } });
  }
}
