import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {APP_BASE_HREF} from '@angular/common';

@Injectable()
export class TokenInterceptorInterceptor implements HttpInterceptor {

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private cookieService: CookieService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => event), // pass further respone
      catchError((error: HttpErrorResponse) => {
        if (error && (error.error.error.errorCode === 'ER-TOKEN-4000' || error.error.error.errorCode === 'ER-IMS-4009')) {
          let errort=error.error.error.clientErrorMessage;
          if(this.cookieService.get('selectedCountryCode')==='fr'){
            errort=error.error.error.clientErrorMessageInFrench;
          }
          this.toastrService.error(errort);
          localStorage.clear();
          setTimeout(() => {
            window.location.href = this.baseHref+'login';
          }, 1000);
          
        }
        return throwError(error);
      })
    );
  }
}
