import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Globals } from '../globals';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  headers: any;
  _baseURL: any;
  user: any;
  _baseUpoadURL: string;
  constructor(
    private httpClient: HttpClient,
    private globaols: Globals
    ) {
    this.user=this.globaols.getUser();
    this.headers = new HttpHeaders().set('content-type', 'application/json');
    this._baseURL = environment.appConfig.apiUrl;
    this._baseUpoadURL = environment.appConfig.apiUploadUrl;
  }
  _getCountyList(): Observable<any>{
    return this.httpClient.get(this._baseURL + 'api/ims/v1/config/get/countries', { headers: this.headers });
  }
  _getStateList(countryId: any): Observable<any>{
    return this.httpClient.get(this._baseURL + 'api/ims/v1/config/get/states/'+countryId, {headers: this.headers });
  }
  _getCityList(stateId: any): Observable<any>{
    return this.httpClient.get(this._baseURL + 'api/ims/v1/config/get/cities/'+stateId, {headers: this.headers });
  }
  _fileSingleUpload(obj: any): Observable<any>{
    //let deviceId =Date.now();
    let headerss= new HttpHeaders().set('Access-Token',this.user.token);
    return this.httpClient.post(this._baseUpoadURL + 'api/document-store/v1/upload',obj, {headers: headerss });
  }
  _sendPushNotification(obj: any): Observable<any> {
    let serverKey = environment.firebaseServerKey;
    let hh = new HttpHeaders({
      'Authorization': 'key=' + serverKey,
      'Content-type': 'application/json'
    });
    return this.httpClient.post(environment.firebaseFcmUrl, obj, { headers: hh });
  }
  _getPresignedFileUrl(filename:any) {
    let obj={
      key:filename
    }
    let headerss= new HttpHeaders().set('Access-Token',this.user.token);
    return new Promise((resolve, reject) => { 
      this.httpClient.post(this._baseUpoadURL + 'api/document-store/v1/get/presigned', obj, { headers:headerss }).subscribe({
       next: (x: any) => {
       
        return resolve(x.data.preSignedUrl);
       },
       error: (err: Error) => {
        return reject(err);
       },
       complete: () => {
       },
     });
    });
  } 
  
}
