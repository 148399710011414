import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders,HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  headers: any;
  _baseURL: any;
  _baseURL1: string;
  deviceId: any;
  headersLogin: any;
  deviceInfo: any;
  constructor(private httpClient: HttpClient,private deviceService:DeviceDetectorService) { 
    this.deviceId=Date.now();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    let devicedetail:any= {
      platform: 'Website',
      browser: this.deviceInfo.browser,
      browserVersion: this.deviceInfo.browser_version,
      osVersion: this.deviceInfo.os_version,
      deviceId: this.deviceInfo.device + this.deviceInfo.deviceType,
      appVersion: '',
      ipAddress: '',
      macAddress: '',
    }
    this.headers = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', JSON.stringify(devicedetail));
    this._baseURL = environment.appConfig.apiUrl;
    this._baseURL1 = environment.appConfig.apiUrl1;
   
    
  }
  
  getLogin(obj:any,appToken:any=''): Observable<any>{
    this.headersLogin = new HttpHeaders().set('content-type', 'application/json').set('User_Agent', '{"platform" : "website","browser" : "","browserVersion" : "","osVersion" : "", "deviceId" : '+this.deviceId+',"appVersion" : "","ipAddress" : "","macAddress" : ""}').set('APP-Token',appToken);;
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/signin', obj, { headers: this.headersLogin });
  }
  signUp(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/signup', obj, { headers: this.headers });
  }
  signUpVerification(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/perform/action', obj, { headers: this.headers });
  }
  _forgotPassword(obj:any): Observable<any>{
    return this.httpClient.put(this._baseURL + 'api/ims/v1/partner/forgot/password', obj, { headers: this.headers });
  }
  _forgotPasswordVerification(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/verify/forgot/password', obj, { headers: this.headers });
  }
  _resendOtp(obj:any): Observable<any>{
    let device: any=Date.now()+'lty';
    let header1= new HttpHeaders().set('accept', 'application/json').set('data-hash', 'testww1').set('device-id', device).set('Content-Type', 'application/json');
    return this.httpClient.put(this._baseURL1 + 'api/communicator/v1/otp/resend', obj, { headers: header1 });
  }
  _logout(obj:any): Observable<any>{
    return this.httpClient.post(this._baseURL + 'api/ims/v1/partner/signout', obj, { headers: this.headers });
  }
  
}
