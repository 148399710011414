import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RoleService } from '../_services/role.service';
import { catchError, map, of } from 'rxjs';

export const AuthGuardGuard = (ActivatedRouteSnapshot: any,RouterStateSnapshot: any) => {
  const router = inject(Router);
  checkUserLogin(router);
};

function checkUserLogin(route:Router){
  let auth = JSON.parse(localStorage.getItem('partner_user')|| '{}');
  if (!auth) {
  route.navigate(['/login']);
  return false;
  }

  if (!auth.token) {
  route.navigate(['/login']);
  return false;
  }
  if (auth.userType != 'PARTNER' && auth.userType != 'AGENT') {
  route.navigate(['/login']);
  return false;
  }
  return true;
}
export const isUseActiveGuard = (ActivatedRouteSnapshot: any,RouterStateSnapshot: any) => {
  const router = inject(Router);
  const toasterService = inject(ToastrService);
  isUseActive(router,toasterService);
};


function isUseActive(route:Router,toasterService:ToastrService){
  let auth = JSON.parse(localStorage.getItem('partner_user')|| '{}');
  //alert(auth.partnerState);
  if (auth.partnerState == 'ACTIVE' &&  auth.userType === 'PARTNER') {
      toasterService.error('Your account approval is Pending.Please contact to administrator','')
      route.navigate(['/dashboard']);
      return false;
  }
  return true;
}
export const checkUseRoleGuard = (ActivatedRouteSnapshot: any,RouterStateSnapshot: any,roleServices: RoleService) => {
  const router = inject(Router);
  const roleService = inject(RoleService);
  const toasterService = inject(ToastrService);
  checkUseRole(router,ActivatedRouteSnapshot,toasterService,roleService);
};
function checkUseRole(router:Router,activatedRouteSnapshot:ActivatedRouteSnapshot,toasterService:ToastrService,roleService:RoleService){
  let auth = JSON.parse(localStorage.getItem('user')|| '{}');
  console.log(auth);
  return;
  if (auth.userType === 'user') {
      return;
  }
  let obj = {
    userId:auth.partnerId
  }
  roleService._getUserRoleByUserId(obj).subscribe(
    (data: any) => {
      let userRole: any=data.data.useCasesList;
      let checkRole= userRole.find(
        (element: any) => element.name == activatedRouteSnapshot.data['role']
      )
      let msg='';
      if(checkRole){
        let checkPerMissions= checkRole.permissionIds.find(
          (element: any) => element == activatedRouteSnapshot.data['pid']
        )
        if(activatedRouteSnapshot.data['pid']==1){
          msg= 'You have not read permit';
        }
        if(activatedRouteSnapshot.data['pid']==2){
          msg= 'You have not write permit';
        }
        if(activatedRouteSnapshot.data['pid']==3){
          msg= 'You have not delete permit';
        }
        if(!checkPerMissions){
        toasterService.error(msg,'');
        router.navigate(['/dashboard']);
        }else{
          return;
        }
      }else{
        router.navigate(['/dashboard']);
      }
    })
}
  
