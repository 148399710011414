<section id="marketing">
    <div class="container">
        <div class="section-title">
            <div class="h1" i18n="@@market1">Marketing</div>
        </div>
        <p i18n="@@market2">With LTY, explore the future of insurance! Your entryway to a world of comfort, security, and savings is our cutting-edge app.  Say goodbye to the hassle of traditional insurance and embrace a smarter way to protect what matters most to you.</p>
        
        <div class="h4 mb-2" i18n="@@market3">Key Features</div>
        <p i18n="@@market4">Comprehensive Coverage: LTY offers a wide range of insurance options to suit your unique needs, whether it's auto, home, health, or life insurance.</p>
        <p i18n="@@market5">Competitive Rates: We understand the value of your hard-earned money. With LTY, you'll get affordable rates without compromising on coverage.</p>
        <p i18n="@@market6">Hassle-Free Claims: Filing a claim has never been this easy. Our user-friendly interface guides you through the process, ensuring a smooth experience during your time of need.</p>
        <p i18n="@@market7">Security and Privacy: LTY employs top-notch security measures to keep your information safe and sound.</p>
        <p i18n="@@market8">Anytime, Anywhere Access: Whether you're at home or on the go, LTY is at your fingertips. Access your policy information and make changes whenever and wherever you need to.</p>
        <p i18n="@@market9">Smart Savings: Unlock discounts and rewards based on your usage and behavior. LTY helps you save while staying protected.</p>
        <p i18n="@@market10">Join the thousands of happy customers who have already switched to LTY and experience insurance the right way: straightforward, personalized, and for you.</p>
        <p i18n="@@market11">Download the LTY app now and take control of your insurance journey! <br />
            <a href="https://www.getinsuredwithlty.com" target="_blank">https://www.getinsuredwithlty.com</a> <br />
            <a href="https://www.ltyinsurenow.com" target="_blank">https://www.ltyinsurenow.com</a></p>
    </div>
</section>