import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'appFilter' })
export class FilterPipe implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    let filteredUsers= [];
    for (let user of items) {
      if (user['name'].toLowerCase().includes(searchText.toLowerCase())) {
        filteredUsers.push(user);
      }
    }
    return filteredUsers;
   // return items.filter((option: string) => option.toLowerCase().includes(searchText));
  }
}
@Pipe({ name: 'customerFilter' })
export class customerFilter implements PipeTransform {
  /**
   * Pipe filters the list of elements based on the search text provided
   *
   * @param items list of elements to search in
   * @param searchText search string
   * @returns list of elements filtered by search text or []
   */
  transform(items: any[], searchText: string): any[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();
    let filteredUsers= [];
    for (let user of items) {
      if (user['firstName'].toLowerCase().includes(searchText.toLowerCase())) {
        filteredUsers.push(user);
      }
    }
    return filteredUsers;
   // return items.filter((option: string) => option.toLowerCase().includes(searchText));
  }
  
}

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(text: string, by: string, index: number = 1) {
    let arr = text.split(by); // split text by "by" parameter
    return arr[index] // after splitting to array return wanted index
  }
}