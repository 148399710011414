import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-customer-nav',
  templateUrl: './customer-nav.component.html',
  styleUrls: ['./customer-nav.component.css']
})
export class CustomerNavComponent {
  @Input() customerName: any='';
  @Input() customerId: any='';
  @Input() activeMenu: any='';
  constructor(
    private router: Router,
    private cookieService: CookieService
  ) {
    
   }

  ngOnInit(): void {
   
  }

}
