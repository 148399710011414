import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HeaderComponent as HomeHeaderComponent } from './common/partner-front/header/header.component';
import { FooterComponent as HomeFooterComponent } from './common/partner-front/footer/footer.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { HeaderComponent as DashboardHeaderComponent } from './common/partner-dashboard/header/header.component';
import { SidebarComponent } from './common/partner-dashboard/sidebar/sidebar.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { Globals } from './globals';
import { TokenInterceptorInterceptor } from './_interceptor/token-interceptor.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { provideUserIdleConfig } from 'angular-user-idle';
import { BnNgIdleService } from 'bn-ng-idle';
import { SelectAllCheckboxComponent } from './select-all-checkbox/select-all-checkbox.component';
import { SharedModule } from './shared/shared.module';
import { FileDragDropDirective } from './_directives/file-drag-drop.directive';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { environment } from 'src/environments/environment';
import { PrivacyComponent } from './privacy/privacy.component';
import { MarketingComponent } from './marketing/marketing.component';
import { SupportComponent } from './support/support.component';
@NgModule({
  declarations: [
    AppComponent,
    HomeHeaderComponent,
    HomeFooterComponent,
    DashboardLayoutComponent,
    HomeLayoutComponent,
    DashboardHeaderComponent,
    SidebarComponent,
    PagenotfoundComponent,
    SelectAllCheckboxComponent,
    FileDragDropDirective,
    PrivacyComponent,
    MarketingComponent,
    SupportComponent
  ],
  imports: [  
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      //positionClass :'toast-bottom-right'
      //preventDuplicates:true
    }),
    NgbModule,
    NgScrollbarModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthModule,
    AngularFireDatabaseModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorInterceptor,
      multi: true,
    }, 
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseConfig },
    provideUserIdleConfig({ idle: 60, timeout: 60, ping: 30 }),
    BnNgIdleService,
    Globals
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
