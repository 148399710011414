import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-analysis-nav',
  templateUrl: './data-analysis-nav.component.html',
  styleUrls: ['./data-analysis-nav.component.css']
})
export class DataAnalysisNavComponent {
  @Input() activeMenu: any='';

}
