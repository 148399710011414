import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { AuthGuardGuard, isUseActiveGuard ,checkUseRoleGuard} from './_guards/auth-guard.guard';

const routes: Routes = [
  {
  path: '',
  component: HomeLayoutComponent,
  children:[
    {
    path: '',
    redirectTo:'/login',
    pathMatch:'full'
    },
    {
      path: '',
      loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
    },
    {
      path: '',
      loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    }
  ]
  },
  {
  path: '',
  component: DashboardLayoutComponent,
  children:[
    {
    path: '',
    redirectTo:'dashboard',
    pathMatch:'full'
    },
    {
      path: 'dashboard',
      loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
      canActivate: [AuthGuardGuard]
    },
    {
      path: 'profile',
      loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'agent-network',
      loadChildren: () => import('./agent-network/agent-network.module').then(m => m.AgentNetworkModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard]
    },
    {
      path: 'agent/commission',
      loadChildren: () => import('./commission/commission.module').then(m => m.CommissionModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'category',
      loadChildren: () => import('./category/category.module').then(m => m.CategoryModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'subcategory',
      loadChildren: () => import('./subcategory/subcategory.module').then(m => m.SubcategoryModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'product',
      loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'customer',
      loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard]
    },
    {
      path: 'customer-family',
      loadChildren: () => import('./customers-family-info/customers-family-info.module').then(m => m.CustomersFamilyInfoModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'proposal',
      loadChildren: () => import('./proposals/proposals.module').then(m => m.ProposalsModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'lead',
      loadChildren: () => import('./leads/leads.module').then(m => m.LeadsModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'submission',
      loadChildren: () => import('./submissions/submissions.module').then(m => m.SubmissionsModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'customer/contract',
      loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'customer/document',
      loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'role-access',
      loadChildren: () => import('./role-access/role-access.module').then(m => m.RoleAccessModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'message',
      loadChildren: () => import('./message/message.module').then(m => m.MessageModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'financial',
      loadChildren: () => import('./financial-statement/financial-statement.module').then(m => m.FinancialStatementModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'data-analysis-extraction',
      loadChildren: () => import('./data-analysis-extraction/data-analysis-extraction.module').then(m => m.DataAnalysisExtractionModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'agenda',
      loadChildren: () => import('./agenda/agenda.module').then(m => m.AgendaModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    },
    {
      path: 'expire-contract',
      loadChildren: () => import('./expired-contract/expired-contract.module').then(m => m.ExpiredContracModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    }, 
    {
      path: 'newsletter',
      loadChildren: () => import('./newsletter/newsletter.module').then(m => m.NewsletterModule),
      canActivate: [AuthGuardGuard,isUseActiveGuard],
      data:{
      }
    }
  ]
  },
  { 
    path: 'about', 
    component: PagenotfoundComponent
  },
  { 
    path: '**', 
    pathMatch: 'full', 
    component: PagenotfoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
