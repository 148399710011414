<section id="marketing">
    <div class="container">
        <div class="section-title mb-4">
            <div class="h1" i18n="@@support1"> Suport</div>
        </div>
        
        <div class="h4 mb-2" i18n="@@support2">License of use</div>
        <ul class="bullets">
            <li i18n="@@support3">The Company grants You a license to install and use any copy of the Application on any mobile device You own or possess. You may not redistribute the Application, publish it on a download platform, modify it or take over its development on Your behalf in any way whatsoever.</li>
            <li i18n="@@support4">This license is granted to You on a non-exclusive basis. Its duration is not limited. It is personal and non-transferable. It is granted, free of charge, for use throughout the world. It terminates when You uninstall any copy of the Application from any mobile device that You own or possess.</li>
            <li i18n="@@support5">The Application may include other applications or application modules provided by third parties (messaging, social networks and payment systems). These third-party applications or application modules are subject to the intellectual property rights of said third parties. They are licensed to you under the terms and conditions agreed between you and such third parties. However, the exclusions of liability described below also apply to applications and application modules provided by third parties to the fullest extent permitted by law.</li>
            <li i18n="@@support6">The Company reserves the right to revoke this license, with immediate effect, in the event of a breach of the GCU.</li>
            <li i18n="@@support7">You understand and agree that the granting of a license has no effect on the ownership and title to the Company's intellectual property rights (trademarks, copyrights, etc.) and that these remain the exclusive property of the Company.</li>
        </ul>
        <div class="h4 mb-2" i18n="@@support8">Application updates</div>
        <p i18n="@@support9">The Company constantly strives to provide You with a tool that meets Your most current needs. The Company therefore reserves the right to update the Application at any time. This implies that the Application may evolve over time. The Company may be obliged to remove certain functionalities for legal reasons, or in the event of repeated abuse by users. New features may also be added to improve Your experience as a user. You therefore agree to accept any updates to the Application that may be proposed by the Company.</p>
    </div>
</section>