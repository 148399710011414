<aside class="aside-navbar">
  <figure class="sidebar-logo">
    <img src="assets/images/logo_white.png" alt="">
  </figure>
  <ng-scrollbar class="custom-scrl" style="max-height: calc(100% - 112px);">
    <ul>
       <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/dashboard') }"><a href="javascript:void(0);" [routerLink]="['/dashboard']"><span><i class="bi bi-grid-fill"></i></span><ng-container i18n>Dashboard</ng-container></a></li>
      <ng-container *ngIf="user.userType==='PARTNER'">
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/category') ||  currentUrl.includes('/subcategory') ||  currentUrl.includes('/product') }"><a href="javascript:void(0);" [routerLink]="['/category/list']"><span><i class="bi bi-list-task"></i></span><ng-container i18n>Product Management</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/agent-network') || currentUrl.includes('/agent/commission')}"><a href="javascript:void(0);" [routerLink]="['/agent-network/list']"><span><img src="assets/images/agent_network.svg" alt="Agent Network"></span><ng-container i18n>Agent Network</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/customer') }"><a href="javascript:void(0);" [routerLink]="['/customer/list']"><span><i class="bi bi-people-fill"></i></span><ng-container i18n>Customers</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/proposal/list') || currentUrl.includes('/proposal/add') || currentUrl.includes('/proposal/edit') || currentUrl.includes('/proposal/view') }"><a href="javascript:void(0);" [routerLink]="['/proposal/list']"><span><i class="bi bi-clipboard-data-fill"></i></span><ng-container i18n>Manage Proposal</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/financial') }"><a href="javascript:void(0);"  [routerLink]="['/financial/list']"><span><img src="assets/images/financial_statement.svg" alt="financial statement"></span><ng-container i18n>financial statement</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/newsletter') }"><a href="javascript:void(0);" [routerLink]="['/newsletter/overview']"><span><img src="assets/images/letter_management.svg" alt="newsletter"></span><ng-container i18n>newsletter</ng-container></a></li>
      <li class="nav-item has-dropdown" [ngClass]="{ 'menu-open': currentUrl.includes('/lead') || currentUrl.includes('/submission') || currentUrl.includes('/agenda') }">
        <a href="javascript:void(0);"><span><i class="bi bi-pie-chart-fill"></i></span><ng-container i18n>Task</ng-container></a>
        <ul>
          <li [ngClass]="{ 'dash-active': currentUrl.includes('/lead') }"><a href="javascript:void(0);" (click)="getActiveLeadPath(0)" i18n>Leads</a></li>
          <li [ngClass]="{ 'dash-active': currentUrl.includes('/submission') }"><a href="javascript:void(0);" [routerLink]="['/submission/list']" i18n>Submissions</a></li>
          <li [ngClass]="{ 'dash-active': currentUrl.includes('/agenda')}"><a href="javascript:void(0);" [routerLink]="['/agenda/list']" i18n>Agenda</a></li>
        </ul>
      </li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/role-access') }"><a href="javascript:void(0);" [routerLink]="['/role-access/list']"><span><i class="bi bi-layers-fill"></i></span><ng-container i18n>Role & access</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/data-analysis-extraction') }"><a href="javascript:void(0);" [routerLink]="['/data-analysis-extraction/overall-statistics']"><span><i class="bi bi-search"></i></span><ng-container i18n>Data analysis & extraction</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/message') }"><a href="javascript:void(0);" [routerLink]="['/message/list']"><span><i class="bi bi-envelope-fill"></i></span><ng-container i18n>message</ng-container></a></li>
    </ng-container>
    <ng-container *ngIf="user.userType==='AGENT'">
      <li *ngIf="moduleArray.indexOf('Customers') !==-1" class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/customer') }"><a href="javascript:void(0);" [routerLink]="['/customer/list']"><span><i class="bi bi-people-fill"></i></span><ng-container i18n>Customers</ng-container></a></li>
      <li *ngIf="moduleArray.indexOf('Manage Proposal') !==-1" class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/proposal/list') || currentUrl.includes('/proposal/add') || currentUrl.includes('/proposal/edit') || currentUrl.includes('/proposal/view') }"><a href="javascript:void(0);" [routerLink]="['/proposal/list']"><span><i class="bi bi-clipboard-data-fill"></i></span><ng-container i18n>Manage Proposal</ng-container></a></li>
      <li *ngIf="moduleArray.indexOf('Financial Statement') !==-1" class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/financial') }"><a href="javascript:void(0);" [routerLink]="['/financial/list']"><span><img src="assets/images/financial_statement.svg" alt="financial statement"></span><ng-container i18n>financial statement</ng-container></a></li>
      <li *ngIf="moduleArray.indexOf('Newsletter') !==-1" class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/newsletter') }"><a href="javascript:void(0);" [routerLink]="['/newsletter/overview']"><span><img src="assets/images/letter_management.svg" alt="newsletter"></span><ng-container i18n>newsletter</ng-container></a></li>
      <li [ngClass]="{ 'menu-open': currentUrl.includes('/lead') || currentUrl.includes('/submission') || currentUrl.includes('/agenda') }" *ngIf="moduleArray.indexOf('Lead') !==-1 || moduleArray.indexOf('Submission') !==-1 || moduleArray.indexOf('Agenda') !==-1" class="nav-item has-dropdown">
        <a href="javascript:void(0);"><span><i class="bi bi-pie-chart-fill"></i></span><ng-container i18n>Task</ng-container></a>
        <ul>
          <li *ngIf="moduleArray.indexOf('Lead') !==-1" [ngClass]="{ 'dash-active': currentUrl.includes('/lead')}"><a href="javascript:void(0);" (click)="getActiveLeadPath(0)" i18n>Leads</a></li>
          <li *ngIf="moduleArray.indexOf('Submission') !==-1" [ngClass]="{ 'dash-active': currentUrl.includes('/submission')}"><a href="javascript:void(0);" [routerLink]="['/submission/list']" i18n>Submissions</a></li>
          <li *ngIf="moduleArray.indexOf('Agenda') !==-1" [ngClass]="{ 'dash-active': currentUrl.includes('/agenda')}"><a href="javascript:void(0);" [routerLink]="['/agenda/list']" i18n>Agenda</a></li>
        </ul>
      </li>
      <li *ngIf="moduleArray.indexOf('Data Analysis & Extraction') !==-1" class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/data-analysis-extraction') }"><a href="javascript:void(0);" [routerLink]="['/data-analysis-extraction/overall-statistics']"><span><i class="bi bi-search"></i></span><ng-container i18n="@@dataAnalysId">Data analysis & extraction</ng-container></a></li>
      <li *ngIf="moduleArray.indexOf('Message') !==-1" class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/message') }"><a href="javascript:void(0);" [routerLink]="['/message/list']"><span><i class="bi bi-envelope-fill"></i></span><ng-container i18n>message</ng-container></a></li>
      <li class="nav-item" [ngClass]="{ 'dash-active': currentUrl.includes('/agent/commission') }"><a href="javascript:void(0);" [routerLink]="['/agent/commission/list',user.partnerId]"><span><i class="bi bi-percent"></i></span><ng-container i18n="@@commissionId">Commission Details</ng-container></a></li>
    </ng-container>
    
    </ul>
  </ng-scrollbar>
  
</aside>
