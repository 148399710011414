// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  DATE_FORMAT: 'dd.MM.yyyy',
  DATE_TIME_FORMAT: 'dd.MM.yyyy HH:mm:ss',
  AG_DATE_FORMAT: 'DD.MM.yyyy',
  AG_DATE_TIME_FORMAT: 'MM/DD/yyyy HH:mm:ss',
  appConfig :{
    apiUrl: "https://identity.dev.ltytech.ch/",
    apiUrl1: "https://communicator.dev.ltytech.ch/",
    apiCatUrl: "https://catalogue.dev.ltytech.ch/",
    apiCommissionUrl: "https://fee.dev.ltytech.ch/",
    apiUploadUrl: "https://document-store.dev.ltytech.ch/",
    apiProposalUrl: "https://insurance-aggregator.dev.ltytech.ch/",
    apiRoleUrl: "https://role-access.dev.ltytech.ch/",
    apiTemplateUrl: "https://communicator.dev.ltytech.ch/",
    imgUrl:""
  },
  // appConfig :{
  //   apiUrl: "https://identity.prod.ltytech.ch/",
  //   apiUrl1: "https://communicator.prod.ltytech.ch/",
  //   apiCatUrl: "https://catalogue.prod.ltytech.ch/",
  //   apiCommissionUrl: "https://fee.prod.ltytech.ch/",
  //   apiUploadUrl: "https://document-store.prod.ltytech.ch/",
  //   apiProposalUrl: "https://insurance-aggregator.prod.ltytech.ch/",
  //   apiRoleUrl: "https://role-access.prod.ltytech.ch/",
  //   apiTemplateUrl: "https://communicator.prod.ltytech.ch/",
  //   imgUrl:""
  // },
  firebaseConfig :{
    apiKey: "AIzaSyADkb1y-3LbJymHtDfRs7waUU4WU1LsJUc",
    authDomain: "ltypartner.firebaseapp.com",
    databaseURL: "https://ltypartner-default-rtdb.firebaseio.com",
    projectId: "ltypartner",
    storageBucket: "ltypartner.appspot.com",
    messagingSenderId: "359287358840",
    appId: "1:359287358840:web:a2f6f2b56c84211f9b348d",
    measurementId: "G-YF9MG7SK5G"
  },
  firebaseServerKey: "AAAAU6cyBXg:APA91bH5Iju59XwhNW_Rvu9h9KP_hhYTwbozoVPmW9OJF3JYOInZS-Z8W56eR6beN8ZQRfsYuplhj3087IcLz__dJhWNbi4PMvJlrNkh6vfmmRiJWuLQ6EhutA8muA5g5qF5KYut53Gl",
  firebaseFcmUrl: "https://fcm.googleapis.com/fcm/send",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
