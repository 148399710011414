import { Component, OnInit, Injectable, OnDestroy, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProfileService } from '../../../_services/profile.service';
import { AuthService } from 'src/app/_services/auth.service';
import { NewsletterService } from 'src/app/_services/newsletter.service';
import { Router } from '@angular/router';
import { Globals } from '../../../globals';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/_services/common.service';


@Component({
  selector: 'app-dashboard-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  DATE_TIME_FORMAT= environment.DATE_TIME_FORMAT;
  @Output() userDataEvent = new EventEmitter();
  @Output() roleDateEvent = new EventEmitter<string>();
  Admindetail: any;
  notificationList: any=[];
  totalNotification: any=0;
  profileSubscribeData: any;
  user: any;
  totalUnreadNotification:any=0;
  toastrservice: any;
  constructor(
    private profileservice:ProfileService,
    private AuthService: AuthService,
    private router: Router,
    private globals: Globals,
    private NewsletterService: NewsletterService,
    private commonService: CommonService
  ) {
    this.user=this.globals.getUser();
   }
  ngOnDestroy(): void {
    if(this.profileSubscribeData){
      this.profileSubscribeData.unsubscribe();
    }
  }
  ngOnInit(): void {
    this._getNotificationList();
    this._getProfile();
    setInterval(() => {
      this._getNotificationList();
    }, 5000);
  }
  public _logout(): void {
    let obj={
      token: this.user.token,
      hardSignOut: true
    }
    this.profileSubscribeData=this.AuthService._logout(obj)
    .subscribe({
      next: (result: any) => {
        console.log(result);
        if(result.status=="SUCCESS"){
          localStorage.clear();
          this.router.navigate(['/login']);
        }
      },
      error: (err: any) => {
        let errorm=err.error.error.clientErrorMessage;
        this.toastrservice.showError(errorm, '',2000);
          return;
      },
      complete: () => {
        console.log('complete');
      }
      });
  }
  profileData:any='';
  _getProfile() {
    this.profileSubscribeData=this.profileservice._getProfile()
    .subscribe({
      next: async (result: any) => {
        if(result.status=="SUCCESS"){
          this.profileData=result.data;
          this.profileData.profilePicUrl=await this.commonService._getPresignedFileUrl(this.profileData.profilePic);
          this.userDataEvent.emit(this.profileData);
        }
      },
      error: (err: any) => {

      },
      complete: () => {
        console.log('complete');
      }
      });
  }
und: any=0;
cnt: any=0;
  _getNotificationList(): any {
    //console.log(this._getLoggedInuser);
    let formdata={
      userId:this.user.partnerId
    }
    this.NewsletterService._getNotificationList(formdata).subscribe({
      next: (x: any) => {
       this.notificationList=x.data.notificationList;
       this.totalNotification=x.data.notificationList.length;
       for(let i=0;i<this.totalNotification;i++){
        if(this.notificationList[i]['deepLink']==='LEAD'){
          this.notificationList[i]['notificationRedirectLink']='/lead/list';
          this.notificationList[i]['notificationLinkActive']=true;
        }else if(this.notificationList[i]['deepLink']==='GENERALIZED_PROPOSAL'){
          this.notificationList[i]['notificationRedirectLink']='/proposal/list';
          this.notificationList[i]['notificationLinkActive']=true;
        }else if(this.notificationList[i]['deepLink']==='PERSONALIZED_PROPOSAL'){
          this.notificationList[i]['notificationRedirectLink']='/proposal/list/PERSONALIZED_PROPOSAL';
          this.notificationList[i]['notificationLinkActive']=true;
        }else if(this.notificationList[i]['deepLink']==='PROFILE'){
          this.notificationList[i]['notificationRedirectLink']='/profile';
          this.notificationList[i]['notificationLinkActive']=true;
        }else if(this.notificationList[i]['deepLink']==='USER'){
          this.notificationList[i]['notificationRedirectLink']='/customer/list';
          this.notificationList[i]['notificationLinkActive']=true;
        }else{
          this.notificationList[i]['notificationRedirectLink']='/';
          this.notificationList[i]['notificationLinkActive']=false;
        }

      }
      let unredcnt: any=localStorage.getItem('unreadnCnt')?localStorage.getItem('unreadnCnt'):0;
      if(this.totalNotification>unredcnt){
        this.cnt=parseInt(this.totalNotification)-parseInt(unredcnt);
        this.totalUnreadNotification= this.cnt;
        this.und =parseInt(unredcnt)+parseInt(this.cnt);

        }else if(this.totalNotification==unredcnt) {
            this.totalUnreadNotification=0;
        }else {
          this.totalUnreadNotification=this.totalNotification;
        }
      },
      error: (err: Error) => {
        let errRes: any;
        errRes = err;
        console.error(err)
      },
      complete: () => {

      },
    })
  }
  _getUnreadNotification(){
    let unredcnt: any=localStorage.getItem('unreadnCnt');
    if(this.totalNotification>unredcnt){
    this.totalUnreadNotification=0;
    localStorage.setItem('unreadnCnt', this.totalNotification);
    localStorage.setItem('tunreadnCnt', this.totalUnreadNotification);
    localStorage.setItem('unreadnCnt', this.und);
    localStorage.setItem('tunreadnCnt', this.cnt);
    }else{
      this.totalUnreadNotification=0;
      localStorage.setItem('unreadnCnt', this.totalNotification);
      localStorage.setItem('tunreadnCnt', this.totalUnreadNotification);
      localStorage.setItem('unreadnCnt', this.und);
      localStorage.setItem('tunreadnCnt', this.cnt);
    }


  }

}
