<footer>
    <div class="container">
        <div class="row align-items-center justify-content-between">
            <div class="col-md-4">
                <div class="footer-logo">
                    <a href="javascript:void(0);" [routerLink]="['/']"><img src="assets/images/footer-logo.png" i18n-alt alt="LTY"></a>
                </div>
            </div>
            <div class="col-md-4">
                <div class="footer-copyright" i18n>
                    &copy; LTY 2023
                </div>
            </div>
        </div>
    </div>
</footer>