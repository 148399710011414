import { Component, HostListener } from '@angular/core';
 // import it to your component
 // import it to your component
 import { BnNgIdleService } from 'bn-ng-idle';
 import { IdleTimer } from './idleTimer';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  //pageReloaded=false;
  title = 'partners';
//   @HostListener('window:beforeunload', ['$event'])
//   unloadHandler(event: Event) {
//     let pageReloaded: any = window.performance.getEntriesByType('navigation').map((nav) => (nav as any).type);
//     let type=pageReloaded[0];
//     console.log(type);

//     return  false;
//     if(type!=="reload" && type!=="navigate"){
//       return  false;
//         localStorage.clear();
//         window.location.href = '/logisn';
      
//     }else{
// return true
//     }
//     type="";
//   }
  constructor(private bnIdle: BnNgIdleService) {
    
  }
  ngOnInit() {
    this.bnIdle.startWatching(300).subscribe((isTimedOut: boolean) => {
      if (isTimedOut) {
        //localStorage.clear();
       // window.location.href = '/login';
      }
    });
  }
    
  
}
