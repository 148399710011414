import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import {APP_BASE_HREF} from '@angular/common';
@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.css']
})
export class LanguageSwitcherComponent implements OnInit {
  countryFlag: any="flag01.png";
  public lang: string = '/';
  public selectedCountryCode: string = 'us';
  public countryWithCodes: any = ['us', 'fr'];
  public customLabels: any = {
    'us': 'English',
    'fr': 'France'
  }
  constructor(
    private router: Router,
    private cookieService: CookieService,
    @Inject(APP_BASE_HREF) private baseHref: string
  ) {
    if(this.baseHref==='/'){

      this.cookieService.set('selectedCountryCode','us');
      this.cookieService.set('selectedCountryFlag',this.countryFlag);
     // this.countryFlag='flag01.png';
     this.countryFlag='EN';
    }else{
      let baseHref=this.baseHref.replace(/^\/|\/$/g, '');
      this.cookieService.set('selectedCountryCode',baseHref);
      this.cookieService.set('selectedCountryFlag','flag01.png');
      //this.countryFlag='flag03.png';
      this.countryFlag='FR';
    }
   }

  ngOnInit(): void {
    
  }

  trimLastSlashFromUrl(baseUrl: any='') {
   if (baseUrl[baseUrl.length - 1] == '/') {
    alert(baseUrl);
        let trimmedUrl = baseUrl.substring(0, baseUrl.length - 1);
        return trimmedUrl;
    }
}

  public languageSwitcherAction(value: string = '',flag: any=""): void {
    //this.flag_img=flag;
    if (value == '') { return; }
    let redirectTo = '';
    if(value!=''){
      if(value==='us'){
        redirectTo = (window.location.origin +'/'+ this.router.url).replace(/([^:]\/)\/+/g, "$1");
      }else{
        redirectTo = (window.location.origin +'/'+ value + this.router.url).replace(/([^:]\/)\/+/g, "$1");
      }
      //this.cookieService.set('selectedCountryCode',value);
     // this.cookieService.set('selectedCountryFlag',flag);
    }
    //cconsole.log(redirectTo);
    // this.cookieService.putObject('language', {
    //   language: value
    // });
    // this.cookieService.put('lang', value);
    window.location.href = redirectTo;
  }

  public changeSelectedCountryCode(value: string): void {
    // if (value == '') { return; }
    // if (value == this.selectedCountryCode) { return; }
    // this.selectedCountryCode = value;
    // console.log(this.selectedCountryCode);
    // let urlvalue: string = '/';
    // if(value == 'us') {
    //   urlvalue = '/';
    //   let redirectTo = (window.location.origin + urlvalue + this.router.url).replace(/([^:]\/)\/+/g, "$1");
    //   console.log(redirectTo);
    //   this.cookieService.putObject('language', {
    //     language: urlvalue
    //   });
    //   this.cookieService.put('lang', urlvalue);
    //   window.location.href = redirectTo;
    // } else {
    //   urlvalue = '/ar';
    //   let redirectTo = (window.location.origin + urlvalue + this.router.url).replace(/([^:]\/)\/+/g, "$1");
    //   console.log(redirectTo);
    //   this.cookieService.putObject('language', {
    //     language: urlvalue
    //   });
    //   this.cookieService.put('lang', urlvalue);
    //   window.location.href = redirectTo;
    // }
  }

}
