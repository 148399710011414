    <div class="dropdown lang-dropdown">
    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
        aria-expanded="false">
        <span style="color:#000;">{{countryFlag}}</span>
        <!-- <img src="assets/images/{{countryFlag}}" alt=""> -->
    </button>
    <ul class="dropdown-menu">
      <li (click)="languageSwitcherAction('us','EN')"><a class="dropdown-item" href="javascript:void(0);">
        <!-- <span><img i18n-alt src="assets/images/flag01.png" alt="USA"></span> -->
        <ng-container i18n>English</ng-container>
      </a>
    </li>
      <li (click)="languageSwitcherAction('fr','FR')">
        <a class="dropdown-item" href="javascript:void(0);">
        <!-- <span><img i18n-alt src="assets/images/flag03.png" alt="France"></span> -->
        <ng-container i18n>French</ng-container> </a></li>
      <!-- <li ><a class="dropdown-item" href="javascript:void(0);"><span ><img i18n-alt src="assets/images/flag04.png" alt="Italy"></span> <ng-container i18n>Italian</ng-container> </a></li>
      <li class="active"><a class="dropdown-item" href="javascript:void(0);"><span><img i18n-alt src="assets/images/flag05.png" alt="Portugal"></span> <ng-container i18n>Portuguese</ng-container> </a></li>
      <li><a class="dropdown-item" href="javascript:void(0);"><span><img i18n-alt src="assets/images/flag02.png" alt="Albania"></span> <ng-container i18n>Albanian</ng-container> </a></li> -->
  </ul>
</div>