<section id="privacy">
    <div class="container">
        <div class="section-title">
            <div class="h1" i18n="@@privacy1">Privacy Policy</div>
        </div>
        <div class="h3 mb-2" i18n="@@privacy2">Introduction</div>
        <p i18n="@@privacy3">This Privacy Policy is applicable to all the users of LTY website/app and consequential services and products made available by LTY.</p>
        <p i18n="@@privacy4">Please read this Privacy Policy carefully. By accessing the Website or using any of our Services, You represent/ agree that you have read and understood the terms and conditions of usage, which includes this Privacy Policy and You agree to be bound by all its terms.</p>
        <div class="h3 mb-2 mt-2" i18n="@@privacy5">Interpretation and Definitions</div>
        <div class="h4 mb-2" i18n="@@privacy6">Interpretation</div>
        <p i18n="@@privacy7">The words of which the initial letter is capitalized have meanings defined under the following conditions. The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.</p>
        <div class="h4 mb-2" i18n="@@privacy8">Definitions</div>
        <p i18n="@@privacy9">For the purposes of this Privacy Policy:</p>
        <ul class="bullets">
            <li><strong i18n="@@privacy10">Account</strong> <ng-container i18n="@@privacy23">means a unique account created for You to access our Service or parts of our Service.</ng-container></li>
            <li><strong i18n="@@privacy11">Affiliate</strong> <ng-container i18n="@@privacy24">means an entity that controls, is controlled by or is under common control with a party, where "control" means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.</ng-container></li>
            <li><strong i18n="@@privacy12">Application</strong> <ng-container i18n="@@privacy25">refers to LTY, the software program provided by the Company.</ng-container></li>
            <li><strong i18n="@@privacy13">Company</strong> <ng-container i18n="@@privacy26"> (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to Insurance Company, LTY, USA.</ng-container></li>
            <li><strong i18n="@@privacy14">Cookies</strong> <ng-container i18n="@@privacy27"> are small files that are placed on Your computer, mobile device or any other device by a website, containing the details of Your browsing history on that website among its many uses.</ng-container></li>
            <li><strong i18n="@@privacy15">Country</strong> <ng-container i18n="@@privacy28"> refers to: United States</ng-container></li>
            <li><strong i18n="@@privacy16">Device</strong> <ng-container i18n="@@privacy29"> means any device that can access the Service such as a computer, a cellphone or a digital tablet.</ng-container></li>
            <li><strong i18n="@@privacy17">Personal Data</strong> <ng-container i18n="@@privacy30">is any information that relates to an identified or identifiable individual.</ng-container></li>
            <li><strong i18n="@@privacy18">Service</strong> <ng-container i18n="@@privacy31">refers to the Application or the Website or both.</ng-container></li>
            <li><strong i18n="@@privacy19">Service Provider</strong> <ng-container i18n="@@privacy32">means any natural or legal person who processes the data on behalf of the Company. It refers to third-party companies or individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the Company, to perform services related to the Service or to assist the Company in analyzing how the Service is used.</ng-container></li>
            <li><strong i18n="@@privacy20">Usage Data</strong> <ng-container i18n="@@privacy33">refers to data collected automatically, either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</ng-container></li>
            <li><strong i18n="@@privacy21">Website</strong> <ng-container i18n="@@privacy34">refers to LTY, accessible from</ng-container> <a [routerLink]="['/signup']" target="_blank"><ng-container i18n="@@privacy35">Sign up</ng-container></a></li>
            <li><strong i18n="@@privacy22">You</strong> <ng-container i18n="@@privacy36">mean the individual accessing or using the Service, or the company, or other legal entity on behalf of which such individual is accessing or using the Service, as applicable.</ng-container></li>
        </ul>
        <div class="h3 mb-3" i18n="@@privacy37">Collecting and Using Your Personal Data</div>
        <div class="h4 mb-3" i18n="@@privacy38">Types of Data Collected</div>
        <div class="h5 mb-2" i18n="@@privacy39">Personal Data</div>
        <p i18n="@@privacy40">While using Our Service, We may ask You to provide Us with certain personally identifiable information that can be used to contact or identify You. Personally identifiable information may include, but is not limited to:</p>
        <ul class="bullets">
            <li i18n="@@privacy41">Email address</li>
            <li i18n="@@privacy42">First name and last name</li>
            <li i18n="@@privacy43">Phone number</li>
            <li i18n="@@privacy44">Address, State, Province, ZIP/Postal code, City</li>
            <li i18n="@@privacy45">Usage Data</li>
        </ul>
        <div class="h5 mb-2" i18n="@@privacy46">Usage Data</div>
        <p i18n="@@privacy47">Usage Data is collected automatically when using the Service.</p>
        <p i18n="@@privacy48">Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that You visit, the time and date of Your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
        <p i18n="@@privacy49">When You access the Service by or through a mobile device, We may collect certain information automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, unique device identifiers and other diagnostic data.</p>
        <p i18n="@@privacy50">We may also collect information that Your browser sends whenever You visit our Service or when You access the Service by or through a mobile device.</p>
        <div class="h5 mb-2" i18n="@@privacy51">Information Collected while Using the Application</div>
        <p i18n="@@privacy52">While using Our Application, in order to provide features of Our Application, We may collect, with Your prior permission:</p>
        <ul class="bullets">
            <li i18n="@@privacy53">Information regarding your location</li>
        </ul>
        <p i18n="@@privacy54">We use this information to provide features of Our Service, to improve and customize Our Service. The information may be uploaded to the Company's servers and/or a Service Provider's server or it may be simply stored on Your device.</p>
        <p i18n="@@privacy55">You can enable or disable access to this information at any time, through Your Device settings.</p>
        <div class="h5 mb-2" i18n="@@privacy56">Tracking Technologies and Cookies</div>
        <p i18n="@@privacy57">We use Cookies and similar tracking technologies to track the activity on Our Service and store certain information. Tracking technologies used are beacons, tags, and scripts to collect and track information and to improve and analyze Our Service. The technologies We use may include:</p>
        <ul class="bullets">
            <li><strong i18n="@@privacy58">Cookies or Browser Cookies.</strong> <ng-container i18n="@@privacy129">A cookie is a small file placed on Your Device. You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However, if You do not accept Cookies, You may not be able to use some parts of our Service. Unless you have adjusted Your browser setting so that it will refuse Cookies, our Service may use Cookies.</ng-container></li>
            <li><strong i18n="@@privacy59">Web Beacons.</strong> <ng-container i18n="@@privacy130">Certain sections of our Service and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of a certain section and verifying system and server integrity).</ng-container></li>
        </ul>
        <p i18n="@@privacy60">Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on Your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close Your web browser. We use both Session and Persistent Cookies for the purposes set out below:</p>
        <ul class="bullets_two">
            <li>
                <strong i18n="@@privacy61">Necessary / Essential Cookies</strong>
                <p><strong i18n="@@privacy62">Type:</strong> <ng-container i18n="@@privacy131">Session Cookies</ng-container></p>
                <p><strong i18n="@@privacy63">Administered by:</strong> <ng-container i18n="@@privacy132">Us</ng-container></p>
                <p><strong i18n="@@privacy64">Purpose:</strong> <ng-container i18n="@@privacy133">These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</ng-container></p>
            </li>
            <li>
                <strong i18n="@@privacy36">Cookies Policy / Notice Acceptance Cookies</strong>
                <p><strong i18n="@@privacy65">Type:</strong> <ng-container i18n="@@privacy134">Persistent Cookies</ng-container></p>
                <p><strong i18n="@@privacy66">Administered by:</strong> <ng-container i18n="@@privacy135">Us</ng-container></p>
                <p><strong i18n="@@privacy67">Purpose:</strong> <ng-container i18n="@@privacy136">These Cookies identify if users have accepted the use of cookies on the Website.</ng-container></p>
            </li>
            <li>
                <strong i18n="@@privacy68">Functionality Cookies</strong>
                <p><strong i18n="@@privacy69">Type:</strong> <ng-container i18n="@@privacy137">Persistent Cookies</ng-container></p>
                <p><strong i18n="@@privacy70">Administered by:</strong> <ng-container i18n="@@privacy138">Us</ng-container></p>
                <p><strong i18n="@@privacy71">Purpose:</strong> <ng-container i18n="@@privacy139">These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</ng-container></p>
            </li>
        </ul>
        <p i18n="@@privacy72">For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies section of our Privacy Policy.</p>
        <div class="h4 mb-2" i18n="@@privacy73">Use of Your Personal Data</div>
        <p i18n="@@privacy74">The Company may use Personal Data for the following purposes:</p>
        <ul class="bullets">
            <li><strong i18n="@@privacy75">To provide and maintain our Service,</strong> <ng-container i18n="@@privacy140">including to monitor the usage of our Service</ng-container></li>
            <li><strong i18n="@@privacy76">To manage Your Account:</strong> <ng-container i18n="@@privacy141">to manage Your registration as a user of the Service. The Personal Data You provide can give You access to different functionalities of the Service that are available to You as a registered user.</ng-container></li>
            <li><strong i18n="@@privacy77">For the performance of a contract:</strong> <ng-container i18n="@@privacy142">the development, compliance and undertaking of the purchase contract for the products, items or services You have purchased or of any other contract with Us through the Service.</ng-container></li>
            <li><strong i18n="@@privacy78">To contact You:</strong> <ng-container i18n="@@privacy143">To contact You by email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile application's push notifications regarding updates or informative communications related to the functionalities, products or contracted services, including the security updates, when necessary or reasonable for their implementation.</ng-container></li>
            <li><strong i18n="@@privacy79">To provide You</strong> <ng-container i18n="@@privacy144">with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless You have opted not to receive such information.</ng-container></li>
            <li><strong i18n="@@privacy80">To manage Your requests:</strong> <ng-container i18n="@@privacy145">To attend and manage Your requests to Us.</ng-container></li>
            <li><strong i18n="@@privacy81">For business transfers:</strong> <ng-container i18n="@@privacy146">We may use Your information to evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data held by Us about our Service users is among the assets transferred.</ng-container></li>
            <li><strong i18n="@@privacy82">For other purposes: </strong> <ng-container i18n="@@privacy147">We may use Your information for other purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Service, products, services, marketing and your experience.</ng-container></li>
        </ul>
        <p i18n="@@privacy83">We may share Your personal information in the following situations:</p>
        <ul class="bullets">
            <li><strong i18n="@@privacy84">With Service Providers:</strong> <ng-container i18n="@@privacy148">We may share Your personal information with Service Providers to monitor and analyze the use of our Service, to contact You.</ng-container></li>
            <li><strong i18n="@@privacy85">For business transfers:</strong> <ng-container i18n="@@privacy149">We may share or transfer Your personal information in connection with, or during negotiations of, any merger, sale of Company assets, financing, or acquisition of all or a portion of Our business to another company.</ng-container></li>
            <li><strong i18n="@@privacy86">With Affiliates:</strong> <ng-container i18n="@@privacy150">We may share Your information with Our affiliates, in which case we will require those affiliates to honor this Privacy Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or other companies that We control or that are under common control with Us.</ng-container></li>
            <li><strong i18n="@@privacy87">With business partners:</strong> <ng-container i18n="@@privacy151">We may share Your information with Our business partners to offer You certain products, services or promotions.</ng-container></li>
            <li><strong i18n="@@privacy88">With other users:</strong> <ng-container i18n="@@privacy152">when You share personal information or otherwise interact in the public areas with other users, such information may be viewed by all users and may be publicly distributed outside.</ng-container></li>
            <li><strong i18n="@@privacy89">With Your consent:</strong> <ng-container i18n="@@privacy153">We may disclose Your personal information for any other  purpose with Your consent.</ng-container></li>
        </ul>
        <div class="h4 mb-2" i18n="@@privacy90">Retention of Your Personal Data</div>
        <p i18n="@@privacy91">The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
        <p i18n="@@privacy92">The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>
        <div class="h4 mb-2" i18n="@@privacy93">Transfer of Your Personal Data</div>
        <p i18n="@@privacy94">Your information, including Personal Data, is processed at the Company's operating offices and in any other places where the parties involved in the processing are located. It means that this information may be transferred to — and maintained on — computers located outside of Your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from Your jurisdiction.</p>
        <p i18n="@@privacy95">Your consent to this Privacy Policy followed by Your submission of such information represents Your agreement to that transfer.</p>
        <p i18n="@@privacy96">The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of Your data and other personal information.</p>
        <div class="h4 mb-2"i18n="@@privacy97">Delete Your Personal Data</div>
        <p i18n="@@privacy98">You have the right to delete or request that We assist in deleting the Personal Data that We have collected about You.</p>
        <p i18n="@@privacy99">Our Service may give You the ability to delete certain information about You from within the Service.</p>
        <p i18n="@@privacy100">You may update, amend, or delete Your information at any time by signing in to Your Account, if you have one, and visiting the account settings section that allows you to manage Your personal information. You may also contact Us to request access to, correct, or delete any personal information that You have provided to Us.</p>
        <p i18n="@@privacy101">Please note, however, that We may need to retain certain information when we have a legal obligation or lawful basis to do so.</p>
        <div class="h4 mb-2" i18n="@@privacy102">Disclosure of Your Personal Data</div>
        <div class="h5 mb-2" i18n="@@privacy103">Business Transactions</div>
        <p i18n="@@privacy104">If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be transferred. We will provide notice before Your Personal Data is transferred and becomes subject to a different Privacy Policy.</p>
        <div class="h5 mb-2" i18n="@@privacy105">Law enforcement</div>
        <p i18n="@@privacy106">Under certain circumstances, the Company may be required to disclose Your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
        <div class="h5 mb-2" i18n="@@privacy107">Other legal requirements</div>
        <p i18n="@@privacy108">The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:</p>
        <ul class="bullets">
            <li i18n="@@privacy109">Comply with a legal obligation</li>
            <li i18n="@@privacy110">Protect and defend the rights or property of the Company</li>
            <li i18n="@@privacy111">Prevent or investigate possible wrongdoing in connection with the Service</li>
            <li i18n="@@privacy112">Protect the personal safety of Users of the Service or the public</li>
            <li i18n="@@privacy113">Protect against legal liability</li>
        </ul>
        <div class="h4 mb-2" i18n="@@privacy114">Security of Your Personal Data</div>
        <p i18n="@@privacy115">The security of Your Personal Data is important to Us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While We strive to use commercially acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
        <div class="h3 mb-2" i18n="@@privacy116">Children's Privacy</div>
        <p i18n="@@privacy117">Our Service does not address anyone under the age of 13. We do not knowingly collect personally identifiable information from anyone under the age of 13. If You are a parent or guardian and You are aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We have collected Personal Data from anyone under the age of 13 without verification of parental consent, We take steps to remove that information from Our servers.</p>
        <p i18n="@@privacy118">If We need to rely on consent as a legal basis for processing Your information and Your country requires consent from a parent, We may require Your parent's consent before We collect and use that information.</p>
        <div class="h3 mb-2" i18n="@@privacy119" i18n="@@privacy36">Links to Other Websites</div>
        <p i18n="@@privacy120">Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You visit.</p>
        <p i18n="@@privacy121">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
        <div class="h3 mb-2" i18n="@@privacy122">Changes to this Privacy Policy</div>
        <p i18n="@@privacy123">We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
        <p i18n="@@privacy124" i18n="@@privacy36">We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the "Last updated" date at the top of this Privacy Policy.</p>
        <p i18n="@@privacy125">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
        <div class="h3 mb-2" i18n="@@privacy126">Contact Us</div>
        <p i18n="@@privacy127">If you have any questions about this Privacy Policy, You can contact us:</p>
        <ul class="bullets" >
            <li> <ng-container i18n="@@privacy128">By visiting this page on our website:</ng-container> <a [routerLink]="['/signup']" target="_blank"><ng-container i18n="@@privacy154">Sign up</ng-container></a></li>
        </ul>
    </div>
</section>