export class Globals {
    user:any;
    getUser(){
        let data = localStorage.getItem('partner_user');
        if (data != null) {
          this.user = JSON.parse(data);
        }
        return this.user;
    }
    getImageUrlBucket() {
      //return 'https://craddle.com/SaharaGo/File/';
    }
    _getFileType(filename:any = ''): string {
      let ext = '';
      let imageExtArr = ['bmp', 'cod', 'gif', 'ief', 'jpe', 'jpeg', 'jpg', 'jfif', 'svg', 'tif', 'tiff', 'ras', 'cmx', 'ico', 'pnm', 'pbm', 'pgm', 'ppm', 'rgb', 'xbm', 'xpm', 'xwd', 'png','webp'];
      let videoExtArr = ['mp2', 'mpa', 'mpe', 'mpeg', 'mpg', 'mpv2', 'mp4', 'mov', 'qt', 'lsf', 'lsx', 'asf', 'asr', 'asx', 'avi', 'movie'];
      let docExtArr = ['doc', 'docx'];
      let pdfExtArr = ['pdf'];
  
      if (filename == '') {
        return ext;
      }
      ext = filename.split('.').pop().toLowerCase();
      if (imageExtArr.includes(ext)) {
        return 'image';
      } else if (videoExtArr.includes(ext)) {
        return 'video';
      } else if (docExtArr.includes(ext)) {
        return 'document';
      } else if (pdfExtArr.includes(ext)) {
        return 'pdf';
      } else {
        return '';
      }
    }
    public static get   validImageTypes(): Array<any> { return ['image/gif', 'image/jpg', 'image/jpeg', 'image/png','image/webp','application/pdf'] }
   
    _getLangData(filename:any = ''): string {
      let langArrayData: any=[];
      langArrayData['Category_us']= "Category";
      langArrayData['Category_fr']= "Catégorie";
      langArrayData['SubCategory_us']= "SubCategory";
      langArrayData['SubCategory_fr']= "Sous-catégorie";
      langArrayData['Products_us']= "Products";
      langArrayData['Products_fr']= "Des produits";
      langArrayData['User Name_us']= "User Name";
      langArrayData['User Name_fr']= "Nom d'utilisateur";
      langArrayData['Type_us']= "Type";
      langArrayData['Type_fr']= "Type";
      langArrayData['DOB_us']= "DOB";
      langArrayData['DOB_fr']= "Date de naissance";
      langArrayData['Assigned Agent_us']= "Assigned Agent";
      langArrayData['Assigned Agent_fr']= "Agent assigné";
      langArrayData['Country_us']= "Country";
      langArrayData['Country_fr']= "Pays";
      langArrayData['Status_us']= "Status";
      langArrayData['Status_fr']= "Etat";
      langArrayData['Action_us']= "Action";
      langArrayData['Action_fr']= "Action";
      langArrayData['Lead ID/Offer ID_us']= "Lead Id/OfferId";
      langArrayData['Lead ID/Offer ID_fr']= "Identifiant du prospect / Identifiant de loffre";
      langArrayData['Customer_us']= "Customer";
      langArrayData['Customer_fr']= "client";
      langArrayData['Agent_us']= "Agent";
      langArrayData['Agent_fr']= "Agent";
      langArrayData['Added At_us']= "Added At";
      langArrayData['Added At_fr']= "Ajouté le";
      langArrayData['Email/Phone_us']= "Email/Phone";
      langArrayData['Email/Phone_fr']= "Courriel/Téléphone";
      langArrayData['Cat/Subcat_us']= "Cat/Subcat";
      langArrayData['Cat/Subcat_fr']= "Chat/sous-chat";
      langArrayData['Lead ID_us']= "Lead Id";
      langArrayData['Lead ID_fr']= "Identifiant du prospect";
      langArrayData['Client/Relation_us']= "Client/Relation";
      langArrayData['Client/Relation_fr']= "Client/Relation";
      langArrayData['Relation_us']= "Relation";
      langArrayData['Relation_fr']= "Relation";
      langArrayData['Date_us']= "Date";
      langArrayData['Date_fr']= "Date";
      langArrayData['Request Type_us']= "Request Type";
      langArrayData['Request Type_fr']= "type de demande";
      langArrayData['Policy ID_us']="Policy ID";
      langArrayData['Policy ID_fr']="ID de politique";
      langArrayData['User Name/Type_us']="User Name/Type";
      langArrayData['User Name/Type_fr']="Nom d'utilisateur/type";
      langArrayData['Start Date/End Date_us']="Start Date/End Date";
      langArrayData['Start Date/End Date_fr']="Date de début/Date de fin";
      langArrayData['Assigned Agent_us']="Assigned Agent";
      langArrayData['Assigned Agent_fr']="Agent assigné";
      langArrayData['Enquired On_us']="Enquired On";
      langArrayData['Enquired On_fr']="Interrogée";
      langArrayData['Sub Category_us']="Sub Category";
      langArrayData['Sub Category_fr']="Sous-catégorie";
      langArrayData['Agent Reserve %_us']="Agent Reserve %";
      langArrayData['Agent Reserve %_fr']="% de réserve d'agent";
      langArrayData['Agent Commission %_us']= "Agent Commission %";
      langArrayData['Agent Commission %_fr']="% de commission d'agent";
      langArrayData['Added/Update On_us']="Added/Update On";
      langArrayData['Added/Update On_fr']="Ajouté/mis à jour le";
      langArrayData['Company Reserve %_us']="Company Reserve %";
      langArrayData['Company Reserve %_fr']="% de réserve de l'entreprise";
      langArrayData['Agent Name_us']="Agent Name";
      langArrayData['Agent Name_fr']="Nom d'agent";
      langArrayData['Added On_us']= "Added On";
      langArrayData['Added On_fr']= "Ajouté le";
      langArrayData['Client Name_us']="Client Name";
      langArrayData['Client Name_fr']="Nom du client";
      langArrayData['Start Date_us']="Start Date";
      langArrayData['Start Date_fr']="Date de début";
      langArrayData['End Date_us']="End Date";
      langArrayData['End Date_fr']="Date de fin";
      langArrayData['Member_us']="Member";
      langArrayData['Member_fr']="Membre";
      langArrayData['Member/Relation_us']="Member/Relation";
      langArrayData['Member/Relation_fr']="Membre/Relation";
      langArrayData['Last File Added On_us']="Last File Added On";
      langArrayData['Last File Added On_fr']="Dernier fichier ajouté le";
      langArrayData['File Name_us']="File Name";
      langArrayData['File Name_fr']="Nom de fichier";
      langArrayData['Upload On_us']="Upload On";
      langArrayData['Upload On_fr']="Télécharger sur";
      langArrayData['Added/Updated On_us']= "Added/Updated On";
      langArrayData['Added/Updated On_fr']= "Ajouté/mis à jour le";
      langArrayData['Insurance Company_us']= "Insurance Company";
      langArrayData['Insurance Company_fr']= "Compagnie d'assurance";
      langArrayData['Insurance Product_us']= "Insurance Product";
      langArrayData['Insurance Product_fr']= "Produit d'assurance";
      langArrayData['Insurance Coverage_us']= "Insurance Coverage";
      langArrayData['Insurance Coverage_fr']= "Couverture d'assurance";

      langArrayData['Task_us']= "Task";
      langArrayData['Task_fr']= "Tâche";
      langArrayData['Meeting Date_us']= "Meeting Date";
      langArrayData['Meeting Date_fr']= "Date de la réunion";
      langArrayData['Meeting Hour_us']= "Meeting Hour";
      langArrayData['Meeting Hour_fr']= "Heure de réunion";

      langArrayData['Joiness_us']= "Joinees";
      langArrayData['Joiness_fr']= "Membres";

      langArrayData['S.N_us']= "S.N";
      langArrayData['S.N_fr']= "S.N.";
      langArrayData['Name_us']= "Name";
      langArrayData['Namefr']= "Nom";
      langArrayData['Email_us']= "Email";
      langArrayData['Email_fr']= "E-mail";
      langArrayData['Role_us']= "Role";
      langArrayData['Role_fr']= "Rôle";

      langArrayData['Customer/Policy_us']= "Customer/Policy";
      langArrayData['Customer/Policy_fr']= "Client/Politique";
      langArrayData['Gross Income_us']= "Gross Income";
      langArrayData['Gross Income_fr']= "Revenu brut";
      langArrayData['Partner Reserve_us']= "Partner Reserve";
      langArrayData['Partner Reserve_fr']= "Réserve partenaire";
      langArrayData['Agent Comm./Reserve_us']= "Agent Comm./Reserve";
      langArrayData['Agent Comm./Reserve_fr']= "Agent Comm./Réserve";
      langArrayData['Subscriber Email_us']= "Subscriber Email";
      langArrayData['Subscriber Email_fr']= "E-mail de l'abonné";
      langArrayData['Created By_us']= "Created By";
      langArrayData['Created By_fr']= "Créé par";
      langArrayData['Template Name_us']= "Template Name";
      langArrayData['Template Name_fr']= "Nom du modèle";
      langArrayData['Total Income_us']= "Total Income";
      langArrayData['Total Income_fr']= "Revenu total";
      langArrayData['Total Income_us']= "Total Income";
      langArrayData['Total Income_fr']= "Revenu total";
      langArrayData['Total Amount_us']= "Total Amount";
      langArrayData['Total Amount_fr']= "Montant total";
      langArrayData['Contract Signed_us']= "Contract Signed";
      langArrayData['Contract Signed_fr']= "Contrat signé";
      langArrayData['Proposal Shared_us']= "Proposal Shared";
      langArrayData['Proposal Shared_fr']= "Offres partagées";
      langArrayData['Customers_us']= "Customers";
      langArrayData['Customers_fr']= "Clients";
      langArrayData['Policy Id_us']= "Policy Id";
      langArrayData['Policy Id_fr']= "ID de stratégie";

      langArrayData['Approved_us']= "Approved";
      langArrayData['Approved_fr']= "Approuvé";
      langArrayData['Pending_us']= "Pending";
      langArrayData['Pending_fr']= "En attente";
      langArrayData['Blocked_us']= "Blocked";
      langArrayData['Blocked_fr']= "Bloqué";
      langArrayData['Active_us']= "Active";
      langArrayData['Active_fr']= "Actif";
      langArrayData['ACCEPTED_us']= "Accepted";
      langArrayData['ACCEPTED_fr']= "Accepté";
      langArrayData['SAVED_us']= "Saved";
      langArrayData['SAVED_fr']= "Enregistré";
      langArrayData['POLICY CREATED_us']= "Policy Created";
      langArrayData['POLICY CREATED_fr']= "Police active";
      langArrayData['REJECTED_us']= "Rejected";
      langArrayData['REJECTED_fr']= "Refusée";
      langArrayData['INACTIVE_us']= "Inactive";
      langArrayData['INACTIVE_fr']= "Inactif";
      langArrayData['CLOSED_us']= "Closed";
      langArrayData['CLOSED_fr']= "Fermé";
      langArrayData['Completed_us']= "Completed";
      langArrayData['Completed_fr']= "Complété";
      langArrayData['Not Assigned_us']= "Not Assigned";
      langArrayData['Not Assigned_fr']= "Pas assigné";
      langArrayData['Personal_us']= "Personal";
      langArrayData['Personal_fr']= "Privé";
      langArrayData['Company_us']= "Company";
      langArrayData['Company_fr']= "Entreprise";
      langArrayData['SELF_us']= "Self";
      langArrayData['SELF_fr']= "Soi-même";
      langArrayData['CHILDREN_us']= "Children";
      langArrayData['CHILDREN_fr']= "Enfant";
      langArrayData['SPOUSE_us']= "Spouse";
      langArrayData['SPOUSE_fr']= "Conjoint(e)";
      langArrayData['HEALTH_us']= "Health";
      langArrayData['HEALTH_fr']= "Caisse maladie";
      langArrayData['TRAVEL_us']= "Travel";
      langArrayData['TRAVEL_fr']= "Voyage";
      langArrayData['BUILDING_us']= "Building";
      langArrayData['BUILDING_fr']= "Bâtiment";
      langArrayData['MOTOR_VEHICLE_us']= "Motor Vehicles";
      langArrayData['MOTOR_VEHICLE_fr']= "Véhicules à moteur";
      langArrayData['RC_HOUSEHOLD_us']= "Rc Household";
      langArrayData['RC_HOUSEHOLD_fr']= "RC ménage";
      langArrayData['LEGAL_PROTECTION_us']= "Legal Protection";
      langArrayData['LEGAL_PROTECTION_fr']= "Protection juridique";
      langArrayData['CONSTRUCTION_us']= "Construction";
      langArrayData['CONSTRUCTION_fr']= "Construction";
      langArrayData['QUOTATION_REQUEST_us']= "Quotation Request Valuables";
      langArrayData['QUOTATION_REQUEST_fr']= "Objets de valeur";
      langArrayData['HOUSING_RENTAL_us']= "Housing Rental Guaratee Offer";
      langArrayData['HOUSING_RENTAL_fr']= "Grantie de loyer";
      langArrayData['ANIMAL_us']= "Animal";
      langArrayData['ANIMAL_fr']= "Animaux";
      langArrayData['THIRD_PILLAR_us']= "3rd Piller";
      langArrayData['THIRD_PILLAR_fr']= "3e pilier";
      langArrayData['BUSINESS_LEGAL_PROTECTION_us']= "Business Legal Protection";
      langArrayData['BUSINESS_LEGAL_PROTECTION_fr']= "Protection juridique entreprise";
      langArrayData['BUSINESS_INSURANCE_OFFER_us']= "Business Insurance offer";
      langArrayData['BUSINESS_INSURANCE_OFFER_fr']= "Offre d'assurance entreprise";
      langArrayData['BUSINESS_COMPANY_THINGS_us']= "Companies Things - RCE";
      langArrayData['BUSINESS_COMPANY_THINGS_fr']= "Choses des entreprises - RCE";
      return langArrayData;
   }

public static get languageEn(): Array<any> { 
  
  return [{label:'French', value:'French'}, {label:'English', value:'English'}, {label:'Italian', value:'Italian'}, {label:'Portuguese', value:'Portuguese'}, {label:'Albanian', value:'Albanian'}];
}
public static get languageFr(): Array<any> { 
  
  return  [{label:'Français', value:'French'}, {label:'Anglais', value:'English'}, {label:'Italien', value:'Italian'}, {label:'Portugais', value:'Portuguese'}, {label:'Albanais', value:'Albanian'}];
}

public static get policyTypeEn(): Array<any> { 
  
  return [{label:'Modification of the offer', value:'Modification of the offer'}, {label:'New contract signed', value:'New contract signed'}, {label:'Change in Coverage', value:'Change in Coverage'}, {label:'Company modification', value:'Company modification'}, {label:'Existing policy', value:'Existing policy'}];
}
public static get policyTypeFr(): Array<any> { 

  return [{label:"Modification de l'offre", value:'Modification of the offer'}, {label:'Nouveau contrat signé', value:'New contract signed'}, {label:'Changement de couverture', value:'Change in Coverage'}, {label:'Modification de la compagnie', value:'Company modification'}, {label:'Police existante', value:'Existing policy'}];
}   
public static get agGridLocalTextEn(): any { 
  return {
    of: 'of'
  }
} 

public static get agGridLocalTextFr(): any { 
  
  return {
    of: 'sur'
  }
} 
insuranceTypeLng(lng: any=''){ 
  if(lng==='us'){
    return [{label:"Health", value:'HEALTH'},{label:"Travel", value:'TRAVEL'},{label:"Building", value:'BUILDING'},{label:"Motor Vehicles", value:'MOTOR_VEHICLE'},{label:"Rc Household", value:'RC_HOUSEHOLD'},{label:"Legal Protection", value:'LEGAL_PROTECTION'},{label:"Construction", value:'CONSTRUCTION'},{label:"Quotation Request Valuables", value:'QUOTATION_REQUEST'},{label:"Housing Rental Guaratee Offer", value:'HOUSING_RENTAL'},{label:"Animal", value:'ANIMAL'},{label:"3rd Piller", value:'THIRD_PILLAR'},{label:"Business Legal Protection", value:'BUSINESS_LEGAL_PROTECTION'},{label:"Business Insurance offer", value:'BUSINESS_INSURANCE_OFFER'},{label:"Companies Things - RCE", value:'BUSINESS_COMPANY_THINGS'}];
  }else if(lng==='fr'){
    return [{label:"Caisse maladie", value:'HEALTH'},{label:"Voyage", value:'TRAVEL'},{label:"Bâtiment", value:'BUILDING'},{label:"Véhicules à moteur", value:'MOTOR_VEHICLE'},{label:"RC ménage", value:'RC_HOUSEHOLD'},{label:"Protection juridique", value:'LEGAL_PROTECTION'},{label:"Construction", value:'CONSTRUCTION'},{label:"Objets de valeur", value:'QUOTATION_REQUEST'},{label:"Grantie de loyer", value:'HOUSING_RENTAL'},{label:"Animaux", value:'ANIMAL'},{label:"3e pilier", value:'THIRD_PILLAR'},{label:"Protection juridique entreprise", value:'BUSINESS_LEGAL_PROTECTION'},{label:"Offre d'assurance entreprise", value:'BUSINESS_INSURANCE_OFFER'},{label:"Choses des entreprises - RCE", value:'BUSINESS_COMPANY_THINGS'}];
  }else{
    return [{label:"Health", value:'HEALTH'},{label:"Travel", value:'TRAVEL'},{label:"Building", value:'BUILDING'},{label:"Motor Vehicles", value:'MOTOR_VEHICLE'},{label:"Rc Household", value:'RC_HOUSEHOLD'},{label:"Legal Protection", value:'LEGAL_PROTECTION'},{label:"Construction", value:'CONSTRUCTION'},{label:"Quotation Request Valuables", value:'QUOTATION_REQUEST'},{label:"Housing Rental Guaratee Offer", value:'HOUSING_RENTAL'},{label:"Animal", value:'ANIMAL'},{label:"3rd Piller", value:'THIRD_PILLAR'},{label:"Business Legal Protection", value:'BUSINESS_LEGAL_PROTECTION'},{label:"Business Insurance offer", value:'BUSINESS_INSURANCE_OFFER'},{label:"Companies Things - RCE", value:'BUSINESS_COMPANY_THINGS'}];
  }
}

}
