import { Component, AfterViewInit} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Globals } from '../../../globals';
import { RoleService } from 'src/app/_services/role.service';
import * as $ from 'jquery';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements AfterViewInit {
  currentUrl:any='';
  user: any;
  constructor(
    private router: Router,
    private globals: Globals,
    private roleService: RoleService
  ) {
    this.user=this.globals.getUser();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    })
    
   }
   ngAfterViewInit(): void{
    $(document).on('click','.nav-item>a', function(e){
      $(this).parent().toggleClass('menu-open').siblings().removeClass('menu-open');
    })
   }
   ngOnInit(): void {
    this._checkUseRole();
  }
  checkRolePermission: any=[];
  moduleArray: any=[];
   _checkUseRole(){
    let auth = JSON.parse(localStorage.getItem('partner_user')|| '{}');
    if (auth.userType === 'PARTNER') {
        return;
    }
    let obj = {
      userId:auth.partnerId
    }
    this.roleService._getUserRoleByUserId(obj).subscribe(
      (data: any) => {
        this.checkRolePermission=data.data.useCasesList;
        for(let i=0;i<this.checkRolePermission.length;i++){
          if(this.checkRolePermission[i].permissionIds.length>0){
            this.moduleArray.push(this.checkRolePermission[i].name);
          }
        }
      })
    }
    getActiveLeadPath (page: number=0){
      localStorage.setItem('leadCurrentPage', JSON.stringify(page));
      this.router.navigate(['/lead/list']);
    }
}
